<template>
	<div :class="themeClass" class="Allperfect-Box">
		<div class="allperfect-title">请选择商业类型 : </div>
		<div class="Allperfect-header1" v-loading='loading'>
			<div v-if="showEnterprise" class="Allperfect-header pointer" @click="Enterprise">
				<div class="padding-lr-15 flex-column-center-start card-box">
					<div class="font-size18 font-color-000 font-weight700">企业用户</div>
					<div class="font-size14 font-color-999 margin-t-8">我是企业用户,去完善企业信息</div>
					<!-- <div class="font-size12 font-color-FF9900 margin-t-2">* 需下载授权委托书并上传</div>
					<div class="card-box-button flex-row-center-center margin-t-5 margin-b-2">下载委托书</div> -->
				</div>
				<img src="../../assets/imgs/qiye.png" alt="" class="allperfect-three">
			</div>
			<div v-if="showPerson" class="Allperfect-header pointer" @click="PersonalProfile">
				<div>
					<p class="allperfect-one">个人用户</p>
					<p class="allperfect-two">我是个人用户,去完善个人信息</p>
				</div>
				<img src="../../assets/imgs/geren.png" alt="" class="allperfect-three1">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				showEnterprise: false, //企业显示
				showPerson: false, //个人显示
				loading: false, //加载
				isUnderReview: false //是否审核中
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		created() {
			console.log(this.$route.query)
		},
		mounted() {
			this.getData();
			this.getUserCurrentBill();
		},
		methods: {
			getData() {
				//获取用户完善资料提交单
				this.loading = true
				this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-current-customer-user-info').then(
					result => {
						// console.log(result, "result");
						if (result.obj.fCommerceTypeID == 2) {
							this.showEnterprise = true
						} else if (result.obj.fCommerceTypeID == 1) {
							this.showPerson = true
						} else {
							this.showEnterprise = true
							this.showPerson = true
						}
						this.loading = false;
					},
					rej => {
						this.loading = false;
					}
				);
			},
			//获取用户完善资料
			getUserCurrentBill() {
				this.ApiRequestPostNOMess('api/mall/ebcustomer/adjust-bill/get-current-bill')
					.then(
						res => {
							console.log(res);
							if (res.obj) {
								if (res.obj.fApproveStatus == 1) { //1审核中 254审核成功
									this.isUnderReview = true;
								}
							}
						},
						error => {}
					);
			},
			PersonalProfile() {
				this.$router.push({
					path: "/PersonalContent/PersonalProfile",
					query: ''
				});
			},
			Enterprise() {
				if (this.isUnderReview) {
					this.$router.push({
						path: "/UnderReview",
					});
				} else {
					this.$router.push({
						path: "/PersonalContent/Enterprise",
					});
				}
			},
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.Allperfect-Box {
		width: 917px;
		height: 100%;
		background-color: #FFFFFF;
		margin-top: 13px;
	}

	.allperfect-title {
		font-size: 20px;
		font-weight: bold;
		padding: 11px 0px 0px 13px;
	}

	.Allperfect-header {
		width: 360px;
		height: 109px;
		border-radius: 5px;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.156862745098039);
		border: 0px solid #639af1;
		background-color: #fff;
		position: relative;
		float: left;
		margin-right: 26px;
		margin-top: 40px;
		user-select: none;
	}

	.Allperfect-header1 {
		margin-left: 70px;
	}

	.allperfect-one {
		display: flex;
		font-weight: bold;
		padding: 28px 0px 8px 15px;
		font-size: 18px;
	}

	.allperfect-two {
		font-weight: 400;
		color: #999999;
		padding-left: 15px;
	}

	.Allperfect-header:hover {
		background-color: #f8f9fe;

		.allperfect-three {
			animation: mymove 0.3s infinite;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}

		.allperfect-three1 {
			animation: mymove 0.3s infinite;
			animation-iteration-count: 1;
			animation-fill-mode: forwards;
		}
	}

	@keyframes mymove {
		from {
			right: 10px;
			bottom: 1px;
		}

		to {
			right: 17px;
			bottom: 13px;
		}
	}

	.allperfect-three {
		position: absolute;
		right: 10px;
		bottom: 7px;
		width: 120px;
	}

	.allperfect-three1 {
		position: absolute;
		right: 10px;
		bottom: 1px;
		width: 122px;
	}

	.card-box {
		height: 100%;
	}

	.card-box-button {
		width: 103px;
		height: 24px;
		border-radius: 2px;

		@include themify($themes) {
			color: themed('themes_color');
			border: 1px solid themed('themes_color');
		}
	}
</style>
